import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { FirebaseProvider } from "./context/Firebase";
import { BrowserRouter } from "react-router-dom";
import { IconContext } from "react-icons";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <FirebaseProvider>
        {/* <IconContext.Provider
          value={{ color: "blue", className: "global-class-name" }}
        > */}
        <App />
        {/* </IconContext.Provider> */}
      </FirebaseProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
